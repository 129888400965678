
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { Modules } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "Account",
  components: {},
  setup() {
    const store = useStore();

    let currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const config = computed(() => {
      return store.getters[Modules.APP + "getConfig"];
    });

    function fupUsagePercent() {
      let fup_limit = currentUser.value.subscription
        ? currentUser.value.subscription.plan.fup_limit
        : 0;
      let fup_usage = currentUser.value.subscription
        ? currentUser.value.subscription.fup_usage
        : 0;
      return (fup_limit ? (fup_usage / fup_limit) * 100 : 0).toFixed(2);
    }

    return {
      currentUser,
      fupUsagePercent,
      config,
    };
  },
});
